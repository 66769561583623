/* Colors */
@font-face {
  font-family: Campton;
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-light-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-light-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: bold;
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-bold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-bold-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: 600;
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff") format("woff");
}
.altai-theme-content #bodyView .paywall {
  padding: 20px;
  margin: 20px 0;
  background-color: #f3f3f3;
}
.altai-theme-content #bodyView .paywall .title {
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  font-size: 1.8em;
  font-weight: 500;
  line-height: 1.8em;
  text-align: center;
}
.altai-theme-content #bodyView .paywall .text {
  font-size: 1.3em;
  font-weight: 500;
  text-align: center;
}
.altai-theme-content #bodyView .paywall .comeos-buttons {
  margin: 20px 20px 0 20px;
}
.altai-theme-content #bodyView .paywall .comeos-buttons .comeos-button {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
}
